<template>
    <div id="app">
        <headr  :tabIndex = "tabIndex" @changenav = "changenav"/>
        <router-view/>
        <bottom></bottom>
        <popup></popup>
    </div>
</template>
<script>
import headr from "@/components/header/index"
import bottom from "@/components/bottom/index"
import popup from "@/components/popup/index"
export default {
    name: '',
    props: {},
    components: { headr,bottom,popup },
    provide() {
        return {
            
        }
    },
    data() {
        return {
            tabIndex:0
        };
    },

    watch: {
        '$route'(to) {
           console.log('=-==='+JSON.stringify(to))
            this.tabIndex = to.meta.navindex
        }
    },
    methods: {
        changenav(i){
            console.log(i)
            this.tabIndex = i
        }
    },

};
</script>
<style>
</style>
<style scoped>
*{
    padding: 0;
    margin: 0;

}
body{
    font-size: 12px;
    
}

#id{
    overflow: auto;
    zoom: 1;
    
}
#app{
   position: relative; 
}

</style>