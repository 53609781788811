import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    // { path: '*', redirect: '/' },
    {
        path: '/',
        name: 'Index',
        component: resolve => require(['@/views/index/index'], resolve),
        meta: {
            title: "慧宸科技",
            navindex:0
        },
    },
    {
        path: '/enterprise',
        name: 'enterprise',
        component: resolve => require(['@/views/enterprise/index'], resolve),
        meta: {
            title: "企业服务",
            navindex:2
        },
    },
    {
        path: '/partner',
        name: 'partner',
        component: resolve => require(['@/views/partner/index'], resolve),
        meta: {
            title: "合作商服务",
            navindex:3
        },
    },
    {
        path: '/technology',
        name: 'technology',
        component: resolve => require(['@/views/technology/index'], resolve),
        meta: {
            title: "技术开发服务",
            navindex:4
        },
    },
    
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: resolve => require(['@/views/aboutUs/index'], resolve),
        meta: {
            title: "关于我们",
            navindex:5
        },
    },
    // {
    //     path: '/serve',
    //     name: 'serve',
    //     component: resolve => require(['@/views/aboutUs/index'], resolve),
    //     meta: {
    //         title: "服务",
    //         navindex:4
    //     },
    // }


]

//防止this.$router.push报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // if (to.name == "Index" || window.localStorage.getItem('token')) {
        console.log(to)
        next();
        return;
    // } else {
    //   //  Vue.prototype.$login.show()
    // }
})
export default router