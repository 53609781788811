import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/css/base.scss"
import "../src/utils/rem.js"
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    // 监听路由 写入 title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')