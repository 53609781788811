<template>
<div class="bottoms">
  <div class="page_bottom" id="iviewUi">
       
  </div>
  <div class="page_bottom2 flex">
    <div class="box footer_box">
      <div class="footer_left">
        <img src="@/assets/img/logo2.png"/>
        <p>地址：山东省济南市高新区中垠广场2号楼1单元701</p>
        <p class="flex">© Copyright HuiChen 2019-{{years}} ｜ <a class="ahref" rel="nofollow" target="_blank" href="https://beian.miit.gov.cn/">鲁ICP备2022031530号-1</a>  </p>
      </div>
     
    </div>  
  </div> 
</div>
    
</template>
<script>
//引入three.js
import * as THREE from 'three'
 
export default {
  props: {
    amountX: {
      type: Number,
      default: 50
    },
    amountY: {
      type: Number,
      default: 10
    },
    //控制点颜色
    color: {
      type: String,
      default: 'rgb(2,84,249)'
    },
    top: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      count: 0,
      // 用来跟踪鼠标水平位置
      mouseX: 0,
      windowHalfX: null,
      // 相机
      camera: null,
      // 场景
      scene: null,
      // 批量管理粒子
      particles: null,
      // 渲染器
      renderer: null,
      years:''
    }
  },
  mounted() {
    this.init()
    this.animate()
    let date = new Date()
    this.years = date.getFullYear()
  },
  methods: {
    init: function() {
      const SEPARATION = 100
      const SCREEN_WIDTH = window.innerWidth
      const SCREEN_HEIGHT = window.innerHeight
      const container = document.createElement('div')
      this.windowHalfX = window.innerWidth / 2
      container.style.position = 'relative'
      // container.style.top = `${this.top}px`
      container.style.height = '350px'
      document.getElementById('iviewUi').appendChild(container)
 
      this.camera = new THREE.PerspectiveCamera(75, SCREEN_WIDTH / SCREEN_HEIGHT, 1, 10000)
      this.camera.position.z = 1000
 
      this.scene = new THREE.Scene()
 
      const numParticles = this.amountX * this.amountY
      const positions = new Float32Array(numParticles * 3)
      const scales = new Float32Array(numParticles)
      // 初始化粒子位置和大小
      let i = 0
      let j = 0
      for (let ix = 0; ix < this.amountX; ix++) {
        for (let iy = 0; iy < this.amountY; iy++) {
          positions[i] = ix * SEPARATION - ((this.amountX * SEPARATION) / 2)
          positions[i + 1] = 0
          positions[i + 2] = iy * SEPARATION - ((this.amountY * SEPARATION) / 2)
          scales[j] = 1
          i += 3
          j++
        }
      }
 
      const geometry = new THREE.BufferGeometry()
      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
      geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1))
      // 初始化粒子材质
      const material = new THREE.ShaderMaterial({
        uniforms: {
          color: { value: new THREE.Color(this.color) }
        },
        vertexShader: `
          attribute float scale;
          void main() {
            vec4 mvPosition = modelViewMatrix * vec4( position, 2.0 );
            gl_PointSize = scale * ( 300.0 / - mvPosition.z );
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
        fragmentShader: `
          uniform vec3 color;
          void main() {
            if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
            gl_FragColor = vec4( color, 1.0 );
          }
        `
      })
 
      this.particles = new THREE.Points(geometry, material)
      this.scene.add(this.particles)
 
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      this.renderer.setSize(container.clientWidth, container.clientHeight)
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setClearAlpha(0)
      container.appendChild(this.renderer.domElement)
 
      window.addEventListener('resize', this.onWindowResize, { passive: false })
      document.addEventListener('mousemove', this.onDocumentMouseMove, { passive: false })
      document.addEventListener('touchstart', this.onDocumentTouchStart, { passive: false })
      document.addEventListener('touchmove', this.onDocumentTouchMove, { passive: false })
    },
    render: function() {
      this.camera.position.x += (this.mouseX - this.camera.position.x) * 0.05
      this.camera.position.y = 400
      this.camera.lookAt(this.scene.position)
      const positions = this.particles.geometry.attributes.position.array
      const scales = this.particles.geometry.attributes.scale.array
      // 计算粒子位置及大小
      let i = 0
      let j = 0
      for (let ix = 0; ix < this.amountX; ix++) {
        for (let iy = 0; iy < this.amountY; iy++) {
          positions[i + 1] = (Math.sin((ix + this.count) * 0.3) * 100) + (Math.sin((iy + this.count) * 0.5) * 100)
          scales[j] = (Math.sin((ix + this.count) * 0.3) + 1) * 8 + (Math.sin((iy + this.count) * 0.5) + 1) * 8
          i += 3
          j++
        }
      }
      // 重新渲染粒子
      this.particles.geometry.attributes.position.needsUpdate = true
      this.particles.geometry.attributes.scale.needsUpdate = true
      this.renderer.render(this.scene, this.camera)
      this.count += 0.1
    },
    animate: function() {
      requestAnimationFrame(this.animate)
      this.render()
    },
    onDocumentMouseMove: function(event) {
       this.mouseX = 0
    },
    onDocumentTouchStart: function(event) {
      // if (event.touches.length === 1) {
      //   this.mouseX = event.touches[0].pageX - this.windowHalfX
      // }
			this.mouseX = 0
    },
    onDocumentTouchMove: function(event) {
      // if (event.touches.length === 1) {
      //   event.preventDefault()
      //   this.mouseX = event.touches[0].pageX - this.windowHalfX
      // }
			this.mouseX = 0
    },
    onWindowResize: function() {
      this.windowHalfX = window.innerWidth / 2
      this.camera.aspect = window.innerWidth / window.innerHeight
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(window.innerWidth, window.innerHeight)
    }
  }
}
</script>
<style lang="scss" scoped>
.bottoms{
position: relative;
.ahref{
  color: #fff;
}
.page_bottom2 {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: lighter;
}
}

.footer_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_bottom {
   background: #000000;
	 height: 180px;
   overflow: hidden;
   
}
.footer_left >img {
  width: 150px;
  height: 40px;
}
.footer_left >p {
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
  opacity: .8;
}
.footer_left p img {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 32px;
}
.footer_right {
  display: flex;
}
.footer_right >div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin-left: 40px;
}
.footer_right >div div {
  opacity: .8;
}
.footer_right div img {
  width: 82px;
  height: 82px;
  margin-bottom: 9px;
}
@media (max-width: 1200px) {
  .box{
    width: 90%;
  }
  .page_bottom{
    height: 350px;
   
  }
  .bottoms{
    .page_bottom2{
      font-weight: normal;
      .footer_left >p{
        font-size: 36px;
      }
      .footer_right >div{
        font-size: 36px;
      }
    }
  }
}
</style>