<template>
    <div class="heardediv">
        <header>
            <div class="flex headflex">
                <img class="logoimg" :src="tabIndex === 0 || tabIndex === 5 ? require('@/assets/img/logo2.png') : require('@/assets/img/logo1.png')" @click="goRouter(0)"/>
                <div class="flex1"></div>
                <div class="flex navflex">
                    <div v-for="(item,index) in list" :style="tabIndex === 0 || tabIndex === 5 ? 'color: #fff' : ''" :key="index" @click="goRouter(index)" :class="{active:tabIndex===index}">
                            {{item.name}}
                    </div>
                </div>
                <div class="moble">
                    <img @click="shownav" :src="tabIndex === 0 || tabIndex === 5 ? require('@/assets/img/navimg2.png') : require('@/assets/img/navimg1.png')"/>
                    <div class="moble-nav" v-if="isnav">
                        <div v-for="(item,index) in list" :style="tabIndex === 0 || tabIndex ===5 ? 'color: #fff' : ''" :key="index" @click="goRouter(index)" :class="{active2:tabIndex===index}">
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </div>
        </header>
        
    </div>
</template>
<script>

export default {
    name: 'headr',
    components: {

    },
  
    props:{
        
        tabIndex:{
            type:Number,
            value:0
        } ,
    },
    data() {
        return {
            list: [
                { name: '首页', path: '/' },
                { name: '短消息服务',path:'/sms/sms_index.html'},
                { name: '企业服务', path: '/enterprise' },
                { name: '合作商服务', path: '/partner' },
                { name: '技术开发服务', path: '/technology' },
                { name: '关于我们', path: '/aboutUs' },
                
               
            ],
            userName:'',
            toLogin: false,
            // tabIndex: 0,
            isnav:false
        };
    },

    methods: {
       shownav(){
        this.isnav = !this.isnav
       },
        goRouter(i) {
            this.isnav = false
            // this.tabIndex = i
            this.$emit('changenav',i)
            if(i==1){
                window.open("/sms/index_sms.html",'_self')
               
            }else{
              this.$router.push(this.list[i].path)  
            }
            
            
        },
    },

};
</script>
<style lang="scss" scoped>
.heardediv{
    width: 100%;
    position: absolute;
    top: 0;
	z-index: 1000;
}
header {
    .moble{
        display: none;
        position: relative;
    }
	.headflex{
		margin: 0 auto;
		height: 80px;
		width: 1200px;
        display: flex;
		// justify-content: space-between;
	}
    .flex1{
        flex: 1;
    }
	.logoimg{
		width: 150px;
		height: 40px;
	}
	.navflex{
		width: 560px;
		justify-content: space-between;
	}
    .moble-nav{
        background: rgba(255,255,255,0.7);
        display: block;
        overflow: auto;
        zoom: 1;
        position: absolute;
        left: -100px;
        text-align: center;
        border-radius: 8px;
        div{
            width: 240px;
            color: #666666 !important;
            font-size: 36px;
            padding: 40px 20px;
            border-bottom: 1px solid #fff;
        }
        
    }
    .moble-nav>div:last-child{
        border: 0;
    }
}
@media (max-width: 1200px) {
    header {
        padding-top: 40px;
        .headflex{
            width: 90%;
            // font-size: 16px;
        }
        .moble{
            display: inline-block;
            img{
                width: 100px;
                height: 100px;
            }
        }
        .navflex{
            display: none;
        }
        .logoimg{
            width: 15rem;
            height: 4rem;
        }
    }
}
.navflex div {
    position: relative;
    cursor: pointer;
}
.navflex div:before,
.active:before {
    content: '';
    position: absolute;
    width: 0;
    bottom: -20px;
    height: 3px;
    background-color: #3273F6;
    transition: all 0.3s;
}
.navflex div:hover {
    color: #3273F6 !important;
}
.active:before {
     width: 100% !important;
}
.active {
    color: #3273F6 !important;
}
.moble-nav .active2{
    color: #3273F6 !important;
}
</style>