<template>
<div>
    <div class="gotop">
        <div class="topitem" @click="shopop1()" @mouseover="showimg1=true" @mouseleave="showimg1=false">
            <img src="@/assets/img/index/icon9.png"/>
            <div>微信联系</div>
        </div>
        <div class="topitem" @click="shopop2()" @mouseover="showimg2=true">
            <img src="@/assets/img/index/icon10.png"/>
            <div>电话联系</div>
        </div>
        <div class="topitem" @click="goTop">
            <img src="@/assets/img/index/icon11.png"/>
            <div>回到顶部</div>
        </div>
    </div>
    <img v-show="showimg1" class="popimg" src="https://qxqy-online.sdhckjvip.com/official/scroll/WeChat_popup.png"/>
    <img v-show="showimg2" class="popimg" src="https://qxqy-online.sdhckjvip.com/official/scroll/Phone_popup.png"/>
    <img v-show="showimg3" class="popimg" src="https://qxqy-online.sdhckjvip.com/official/scroll/WeChat_popup.png"/>
    <img v-show="showimg4" class="popimg" src="https://qxqy-online.sdhckjvip.com/official/scroll/Phone_popup.png"/>
</div>
    
</template>
<script>

export default {
    name: 'popup',
    components: {

    },
  
    props:{
        
    },
    data() {
        return {
            upRoll: null,
          showimg1:false,
          showimg2:false,
          showimg3:false,
          showimg4:false
        };
    },

    methods: {
        goTop() {
            this.upRoll = setInterval(() => {
                const top = document.body.scrollTop
                const speed = Math.ceil(top / 10)
                if (document.body.scrollTop > 0) {
                    document.body.scrollTop -= speed
                } else {
                    clearInterval(this.upRoll)
                }
            }, 20);
        },
    //    shopop(e){
    //     if(e===this.popindex){
    //         this.popindex = ''
    //     }else{
    //        this.popindex = e 
    //     }
        
    //    }
    shopop1(){
        this.showimg1=false
        this.showimg2=false
        this.showimg4=false
        this.showimg3=!this.showimg3
    },
      shopop2(){
        this.showimg1=false
        this.showimg2=false
        this.showimg3=false
        this.showimg4=!this.showimg4
    } 
    },

};
</script>
<style lang="scss" scoped>
.gotop{
    position: fixed;
    right:24px;
    bottom: 50px;
    z-index: 1100;
    width: 74px;
    height: 222px;
    .topitem{
        display: flex;
        justify-content: center;
         align-items:center;
         flex-direction: column;
        flex-wrap: wrap;
        font-size: 14px;
        color: #ffffff;
        background: #3273F6;
        height: 74px;
        box-shadow: inset 0px -1px 0px 0px rgba(0,0,0,0.2);
        img{
            width: 24px;
            height: 24px;
            flex: none;
        }
    }
    .topitem:first-child{
        border-radius: 8px 8px 0 0;
    }
    .topitem:last-child{
        box-shadow: none;
        border-radius: 0 0 8px 8px;
    }
}
.popimg{
    width: 300px;
    position: fixed;
    right:100px;
    bottom: 50px;
    z-index: 1100;
}
@media (max-width: 1200px) {
    .gotop{
    
    width: 148px;
    height: 444px;
    .topitem{
        font-size: 28px;
        height: 148px;
        img{
            width: 48px;
            height: 48px;
          
        }
    }
    
    
    }
    .popimg{
        width: 600px;
        position: fixed;
        right:200px;
        bottom: 100px;
        z-index: 1100;
    }
}
</style>